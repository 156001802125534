import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

import './assets/fonts/Fira-Sans_300,500,400,600.css'

ReactDOM
  .createRoot(document.getElementById("root"))
  .render(<App />)
