import React from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import SyncLoaderDetails from "./components/SyncLoaderDetails";

const LayoutJob = React.lazy(() => import("./components/LayoutJob"));
const LayoutJobs = React.lazy(() => import("./components/LayoutJobs"));

const Job = React.lazy(() => import("./pages/Job"));
const Jobs = React.lazy(() => import("./pages/Jobs"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/" element={(
      <React.Suspense fallback={<SyncLoaderDetails />}>
        <LayoutJobs />
      </React.Suspense>
    )}>
      <Route
        index
        /* loader={JobsLoader} */
        element={(
          <React.Suspense fallback={<SyncLoaderDetails />}>
            <Jobs />
          </React.Suspense>
        )}
      />
    </Route>
    <Route path="/:jobID/:plzlocationtitle?" element={(
      <React.Suspense fallback={<SyncLoaderDetails />}>
        <LayoutJob />
      </React.Suspense>
    )}>
      <Route
        index
        /* loader={JobLoader} */
        element={(
          <React.Suspense fallback={<SyncLoaderDetails />}>
            <Job />
          </React.Suspense>
        )}
      />
    </Route>
    <Route path="*" element={(
      <React.Suspense fallback={<SyncLoaderDetails />}>
        <NotFound />
      </React.Suspense>
    )} />
  </>
))

export default function App() {
  return (
    <RouterProvider router={router} />
  )
}
