import React from "react";
import { SyncLoader } from "react-spinners";

import { Container, Grid } from "@mui/material";

export default function SyncLoaderDetails() {
    return (
      <Container>
        <Grid container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}>
          <SyncLoader color={"red"} height={15} width={80} />
        </Grid>
      </Container>
    )
  }